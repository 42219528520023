import { computed, unref, type MaybeRef } from 'vue'
import { useSocketData } from '@autobid/web-socket/src/helper'
import { useCountdown } from '../../useCountdown'
import type { AuctionCar } from '../../../types/Car'

export function useCarCountdown(car: MaybeRef<AuctionCar>) {
  const targetTime = computed(() => {
    const _car = unref(car)
    if (!_car) {
      return new Date()
    }

    const lastBidTimestamp = _car.bids[0]?.timestamp
    const carTimestamp = _car.hotbidStartTime ?? _car.auctionStartDate

    // before hotbid
    if (!_car.inActiveHotbidPhase) return new Date(carTimestamp)

    const finalTimestamp =
      lastBidTimestamp && lastBidTimestamp > carTimestamp
        ? lastBidTimestamp
        : carTimestamp

    return new Date(
      new Date(
        new Date(finalTimestamp).getTime() + 1000 * _car.hotbidDuration.active
      ).toISOString()
    )
  })

  const { socketTimeOffset } = useSocketData()

  return useCountdown(targetTime, socketTimeOffset)
}
